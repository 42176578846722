html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

canvas {
  display: block;
}